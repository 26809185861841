import React from 'react';
import { render } from 'react-dom';
import { InertiaApp } from '@inertiajs/inertia-react';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fas, fab);

if(typeof window !== 'undefined' && document.location.host !== 'localhost:3000' && document.location.host !== 'app.valiantcheckin.co.za.test') {
	Sentry.init({
	  dsn: "https://db5c37f7f7744a0fbbc3f9e862eb60f8@o1116153.ingest.sentry.io/6149519",
	  integrations: [new Integrations.BrowserTracing()],
	  tracesSampleRate: 0.01,
	});
}

const app = document.getElementById('app');

render(
	<InertiaApp
		initialPage={JSON.parse(app.dataset.page)}
		resolveComponent={name =>
	  		import(`./Pages/${name}`).then(module => module.default)
		}
	/>,
	app
);
